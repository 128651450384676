/*
**************************************************************************************************************
            ALETHEIA CAPITAL LIMITED
==============================================================================================================

**************************************************************************************************************
            Amendments
========================================================================
M002 : 27 March 2023 : Zeeshan
 * M-0003827: Forgot Password should send an email with link to reset password
M001 : 24 November 2020 : Nick
    * M-0003042: Email verification and cookie to authenticate users for email based report links\
**************************************************************************************************************
*/

import { reset } from 'redux-form';
import { call, all, put, select, takeLatest, takeEvery } from 'redux-saga/effects';

import {
  logInSuccess,
  logInFail,
  logOutSuccess,
  logOutFail,
  signUpSuccess,
  signUpFail,
  changePasswordSuccess,
  changePasswordFail,
  getCurrentUserSuccess,
  getCurrentUserFail,
  removeUserSuccess,
  removeUserFail,
  getPersonMatrixDetailsSuccess,
  getPersonMatrixDetailsFail,
  updatePersonMatrixDetailsSuccess,
  updatePersonMatrixDetailsFail,
  getAccountManagerSuccess,
  getAccountManagerFail,
  SendEmailSuccess,
  SendEmailFail,
  getUserUnsubLastSentEmailSuccess,
  getUserUnsubLastSentEmailFail,
  updateUserUnsubLastSentEmailSuccess,
  updateUserUnsubLastSentEmailFail,
  saveUnsubscribeReasonSuccess,
  saveUnsubscribeReasonFail,
} from '../actionCreators/User';
import { hideHomePageModal } from '../actionCreators/UserModal';
import { doGet, doPost } from '../utils/request';

function* dosignUp({ obj, history }) {
  try {
    const response = yield doPost('/users/signup', obj);
    yield put(signUpSuccess(response.data));
  } catch (err) {
    const delay = time => new Promise(resolve => setTimeout(resolve, time));
    yield put(signUpFail(err.response.data.message || err.message));
    // yield call(delay, 2000);
    // yield put(signUpFail());
  }
}

function* doLogIn({ obj, history, redirectUrl, redirectBool }) {
  try {
    const response = yield doPost('/users/login', obj);
    yield put(logInSuccess(response.data));

    if (redirectUrl) {
      //====================M001=====[====================
      if (history.location == redirectUrl) {
        window.location.reload();
      }
      //====================M001=====[====================
      history.push(redirectUrl);
      setTimeout(() => {
        if (redirectBool == true) {
          window.location.reload();
        }
      }, 1000);
    } else {
      history.goBack();
    }
  } catch (err) {
    const delay = time => new Promise(resolve => setTimeout(resolve, time));
    yield put(logInFail(err.response.data.message));
    // yield call(delay, 2000);
    // yield put(logInFail());
  }
}

function* doLogOut() {
  try {
    yield put(hideHomePageModal());
    yield doPost('/users/logout');
    yield put(logOutSuccess());
    // window.location = '/';
    // window.location.reload();
  } catch (err) {
    yield put(logOutFail(err.response.data.message));
  }
}

function* getCurrentUser({ history, redirectUrl }) {
  try {
    const response = yield doGet('/users/me');
    yield put(getCurrentUserSuccess(response.data));

    if (redirectUrl && redirectUrl !== '/') {
      const { location } = history;
      if (location.search) {
        history.push(`${redirectUrl}${location.search}`);
      } else {
        history.push(redirectUrl);
      }
    }
  } catch (err) {
    yield put(getCurrentUserFail(err.message));
  }
}

function* changePassword(action) {
  try {
    const response = yield doPost('/users/changePassword', action.obj);
    if (response.data.success === true) {
      yield put(changePasswordSuccess(response.data));
      yield put(reset('ChangePassword'));
    } else {
      yield put(changePasswordFail(response.data, response.data.message));
    }
  } catch (err) {
    yield put(changePasswordFail(err.message));
  }
}

function* removeUser(action) {
  try {
    const response = yield doGet(`/users/${action.obj.id}/removeUser`);
    yield put(removeUserSuccess(action.obj));
  } catch (err) {
    yield put(removeUserFail(action.obj, err.message));
  }
}

function* SaveUnsubscribeReason({ iPSN_Id, iProductId, sOtherText, iReasonId }) {
  const response = yield doGet(
    `/users/SaveUnsubscribeReason/${iPSN_Id}/${iProductId}/${sOtherText}/${iReasonId}`
  );
  try {
    yield put(saveUnsubscribeReasonSuccess(response.data));
  } catch (err) {
    yield put(saveUnsubscribeReasonFail(err.message));
  }
}

//====================M002=====[====================
function* resetPassword({ obj, history, redirectUrl }) {
  try {
    const response = yield doPost('/users/resetUserPassword', obj);
    if (redirectUrl && redirectUrl !== '/' && response.data.success === true) {
      history.push(redirectUrl);
    } else {
      yield put(changePasswordFail(response.data, response.data.message));
    }
  } catch (err) {}
}

function* loadPersonMatrixDetails({ id, userEntity }) {
  const response = yield doGet(`/users/loadPersonMatrixDetails/${id}/${userEntity}`);
  try {
    yield put(getPersonMatrixDetailsSuccess(response.data));
  } catch (err) {
    yield put(getPersonMatrixDetailsFail(err.message));
  }
}

function* updatePersonMatrixDetails({
  psnId,
  psnEntity,
  insertXml,
  deleteXml,
  schemaId,
  schemaFieldId,
}) {
  let url;
  if (deleteXml == '') {
    url = `/Repository/UpdateMatrix?psnId=${psnId}&psnEntity=${psnEntity}&insertXml=${insertXml}&schemaId=${schemaId}&schemaFieldId=${schemaFieldId}`;
  } else {
    url = `/Repository/UpdateMatrix?psnId=${psnId}&psnEntity=${psnEntity}&insertXml=${insertXml}&deleteXml=${deleteXml}&schemaId=${schemaId}&schemaFieldId=${schemaFieldId}`;
  }
  const response = yield doGet(url);
  try {
    yield put(updatePersonMatrixDetailsSuccess(response.data));
  } catch (err) {
    yield put(updatePersonMatrixDetailsFail(err.message));
  }
}

function* getAccountManager({ user }) {
  const response = yield doGet(
    `/Relationships/GetAccountManager?pid=${user}&format=0&hierarchy=3000009&bulletin=0&language=0&user=1`
  );
  try {
    yield put(getAccountManagerSuccess(response.data));
  } catch (err) {
    yield put(getAccountManagerFail(err.message));
  }
}

function* SendEmail({
  emailSubject,
  emailBodyParameterName,
  fromSenderId,
  toIds,
  ccIds,
  bccIds,
  headerParams,
}) {
  const response = yield doGet(
    `/SendEmail?emailSubject=${emailSubject}&emailBodyParameterName=${emailBodyParameterName}&fromSenderId=${fromSenderId}&toIds=${toIds}&ccIds=${ccIds}&bccIds=${bccIds}&headerParams=${headerParams}`
  );
  try {
    yield put(SendEmailSuccess(response.data));
  } catch (err) {
    yield put(SendEmailFail(err.message));
  }
}

function* getUserUnsubLastSentEmail({ id }) {
  const response = yield doGet(`/users/getUserUnsubLastSentEmail/${id}`);
  try {
    yield put(getUserUnsubLastSentEmailSuccess(response.data));
  } catch (err) {
    yield put(getUserUnsubLastSentEmailFail(err.message));
  }
}

function* updateUserUnsubLastSentEmail({ id }) {
  const response = yield doGet(`/users/updateUserUnsubLastSentEmail/${id}`);
  try {
    yield put(updateUserUnsubLastSentEmailSuccess(response.data));
  } catch (err) {
    yield put(updateUserUnsubLastSentEmailFail(err.message));
  }
}

export default function* watcher() {
  yield all([
    takeLatest('SIGNUP', dosignUp),
    takeLatest('LOGIN', doLogIn),
    takeLatest('LOGOUT', doLogOut),
    takeLatest('CHANGE_USER_PASSWORD', changePassword),
    takeLatest('GET_CURRENT_USER', getCurrentUser),
    takeLatest('REMOVE_USER', removeUser),
    takeLatest('RESET_PASSWORD', resetPassword),
    takeLatest('GET_PERSON_MATRIX_DETAILS', loadPersonMatrixDetails),
    takeLatest('UPDATE_PERSON_MATRIX_DETAILS', updatePersonMatrixDetails),
    takeLatest('GET_ACCOUNT_MANAGER', getAccountManager),
    takeLatest('SEND_EMAIL', SendEmail),
    takeLatest('GET_USER_UNSUB_LAST_SENT_EMAIL', getUserUnsubLastSentEmail),
    takeLatest('UPDATE_USER_UNSUB_LAST_SENT_EMAIL', updateUserUnsubLastSentEmail),
    takeLatest('SAVE_UNSUBSCRIBE_REASON', SaveUnsubscribeReason),
  ]);
}
