import React, { useEffect, useState } from 'react';
import FormContainer from '../../components/FormContainer';
import { Link, useLocation } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import * as configActions from '../../actionCreators/Config';
import * as userActions from '../../actionCreators/User';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  createTheme,
  ThemeProvider,
  CssBaseline,
  TextField,
} from '@mui/material';

const theme = createTheme({
  typography: {
    htmlFontSize: 10, // 2rem (default is 16px = 1rem)
  },
});

const UnsubSubscription = ({
  getPickLists,
  Picklists,
  SaveUnsubscribeReason,
  unsubReason,
  currentUser,
  getAccountManager,
  AccountManager,
  getParameter,
  parameters,
  getMatrixData,
  getPersonMatrixDetails,
  updatePersonMatrixDetailsFunc,
  MatrixData,
  UpdatePersonMatrixDetails,
  PersonMatrixDetails,
}) => {
  const [picklistValues, setPicklistValues] = useState([]);
  const [value, setValue] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [otherText, setOtherText] = useState('');
  const [showOtherText, setShowOtherText] = useState(false);
  const [showUnsubscribedModal, setShowUnsubscribedModal] = useState(false);
  const [userEntity, setUserEntity] = useState('');

  const location = useLocation();

  let urlParameters = location.pathname.split('/');
  var productId = urlParameters[2];

  useEffect(() => {
    getPickLists('sUnsubRsns');
    getAccountManager(currentUser.data.id);
    getParameter('scUsrMatrixFieldId,scCntMatrixFieldId');
    getMatrixData();
  }, []);

  useEffect(() => {
    if (Picklists.loading == false && Picklists.data.length) {
      console.log('picklists =====>', Picklists.data);
      const parser = new DOMParser();
      const xml = parser.parseFromString(Picklists.data, 'application/xml');
      // Get all <Picklist> elements
      const picklists = xml.getElementsByTagName('Picklist');

      // Collect <Value> elements whose direct parent is <Picklist>
      const values = [];
      Array.from(picklists).forEach(picklist => {
        // Use only direct children <Value> of <Picklist>
        Array.from(picklist.children).forEach(child => {
          if (child.tagName === 'Value') {
            const mnemonic = child.getAttribute('Mnemonic');
            const valueId = child.getAttribute('ValueId');
            const sort = child.getAttribute('Sort');
            const textContent = child.textContent.trim();

            values.push({ mnemonic, valueId, sort, textContent });
          }
        });
      });

      console.log('Extracted Values:', values);
      setPicklistValues(values);
      setValue(values[0].mnemonic);
      setSelectedId(values[0].valueId);
    }
  }, [Picklists]);

  useEffect(() => {
    if (AccountManager.loading == false && AccountManager.data.length) {
      const parser = new DOMParser();
      const xml = parser.parseFromString(AccountManager.data, 'application/xml');
      const myEntityData = xml
        .querySelector(`psn[id="${currentUser.data.id}"]`)
        .getAttribute('ent');
      const userEnt = myEntityData.replace(/\s+/g, '');
      console.log('user entity =====>', userEnt);
      setUserEntity(userEnt);
    }
  }, [AccountManager]);

  useEffect(() => {
    if (userEntity != '') {
      getPersonMatrixDetails(currentUser.data.id, userEntity);
    }
  }, [userEntity]);

  useEffect(() => {
    if (value == 'Other') {
      setShowOtherText(true);
    } else {
      setShowOtherText(false);
      setOtherText('');
    }
  }, [value]);

  const handleChange = event => {
    setValue(event.target.value);
    setSelectedId(event.target.id);
  };

  const unsubscribeClickHandler = () => {
    if (otherText == '') {
      SaveUnsubscribeReason(currentUser.data.id, productId, 'NA', selectedId);
    } else {
      SaveUnsubscribeReason(currentUser.data.id, productId, otherText, selectedId);
    }
    let xmlToAddStr = '';
    let xmlToRemoveStr = '';
    const parser = new DOMParser();
    const xml = parser.parseFromString(PersonMatrixDetails.data, 'application/xml');
    const MatrixDataXML = parser.parseFromString(MatrixData.data, 'application/xml');
    const matchingElement = xml.querySelector(`y[value="${productId}"]`);
    let zValue = 2120300010;
    if (matchingElement) {
      zValue = matchingElement.querySelector('z').textContent;
    }
    xmlToRemoveStr += `<matrix><x>300001<y>${productId}<z>${zValue}</z></y></x></matrix>`;
    let userType;
    let psnSchemaId;
    let psnSchemaFieldId;
    if (userEntity == 'U') {
      userType = 'U';
      psnSchemaId = 'scUsrMatrix';
      psnSchemaFieldId = parameters?.scUsrMatrixFieldId;
    } else {
      userType = 'C';
      psnSchemaId = 'scCntMatrix';
      psnSchemaFieldId = parameters?.scCntMatrixFieldId;
    }
    updatePersonMatrixDetailsFunc(
      currentUser.data.id,
      userType,
      xmlToAddStr,
      xmlToRemoveStr,
      psnSchemaId,
      psnSchemaFieldId
    );
    setShowUnsubscribedModal(true);
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Modal show={showUnsubscribedModal} onHide={() => setShowUnsubscribedModal(false)}>
        <Modal.Body>
          <div style={{ padding: '20px', textAlign: 'center', fontSize: '22px' }}>
            Unsubscribed Successfully
          </div>
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <div>
            <Button
              bsStyle="primary"
              onClick={() => {
                window.location.href = '/subscriptionPreference/settings';
                setShowUnsubscribedModal(false);
              }}
            >
              OK
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <FormContainer>
        <div style={{ paddingTop: 30 }}>
          <div
            style={{
              textAlign: 'center',
              fontSize: '22px',
              fontWeight: 'bold',
              marginBottom: '50px',
            }}
          >
            Reason for Unsubscribe
          </div>
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                {picklistValues.map((value, index) => (
                  <FormControlLabel
                    value={value.mnemonic}
                    id={value.valueId}
                    key={value.valueId}
                    control={<Radio id={value.valueId} />}
                    label={value.textContent}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          {showOtherText ? (
            <TextField
              id="outlined-multiline-static"
              label="Reason"
              multiline
              rows={4}
              style={{ width: '100%' }}
              value={otherText}
              onChange={e => setOtherText(e.target.value)}
            />
          ) : null}

          <hr />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Link href={'/'} to={'/'}>
              <Button>Cancel</Button>
            </Link>
            <Button bsStyle="primary" onClick={unsubscribeClickHandler}>
              Unsubscribe
            </Button>
          </div>
        </div>
      </FormContainer>
    </ThemeProvider>
  );
};

const mapStateToProps = ({
  user: {
    currentUser,
    unsubReason,
    AccountManager,
    PersonMatrixDetails,
    UpdatePersonMatrixDetails,
  },
  Config: { parameters, Picklists, MatrixData },
}) => ({
  parameters,
  Picklists,
  currentUser,
  unsubReason,
  AccountManager,
  MatrixData,
  UpdatePersonMatrixDetails,
  PersonMatrixDetails,
});

const mapDispatchToProps = dispatch => ({
  getPickLists: bindActionCreators(configActions.getPickLists, dispatch),
  getParameter: bindActionCreators(configActions.getParameter, dispatch),
  SaveUnsubscribeReason: bindActionCreators(userActions.saveUnsubscribeReason, dispatch),
  getAccountManager: bindActionCreators(userActions.getAccountManager, dispatch),
  getMatrixData: bindActionCreators(configActions.getMatrixData, dispatch),
  getPersonMatrixDetails: bindActionCreators(userActions.getPersonMatrixDetails, dispatch),
  updatePersonMatrixDetailsFunc: bindActionCreators(
    userActions.updatePersonMatrixDetails,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnsubSubscription);
