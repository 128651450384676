import axios from 'axios';
import { UNAUTHORIZED } from 'http-status-codes';

import config from '../config/index.json';

const baseURL = window.runtimeConfig?.API_URL || 'http://localhost:9001';

export const doGet = path =>
  new Promise((resolve, reject) =>
    axios({
      url: path,
      baseURL,
      method: 'GET',
      withCredentials: true,
    })
      .then(result => resolve(result))
      .catch(err => {
        reject(err);
      })
  );

export const doPost = (path, data) =>
  new Promise((resolve, reject) =>
    axios({
      url: path,
      baseURL,
      method: 'POST',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
      .then(result => resolve(result))
      .catch(err => {
        if (err.response) {
          if (String(path).toLowerCase() !== '/users/me') {
            if (err.response.status === UNAUTHORIZED) {
              window.location.reload();
            }
            reject(err);
          }
        } else {
          reject(err);
        }
      })
  );

export const doUpload = (path, data) =>
  new Promise((resolve, reject) =>
    axios({
      url: path,
      baseURL,
      method: 'POST',
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    })
      .then(result => resolve(result))
      .catch(err => {
        if (err.response) {
          if (String(path).toLowerCase() !== '/users/me') {
            if (err.response.status === UNAUTHORIZED) {
              window.location.reload();
            }
            reject(err);
          }
        } else {
          reject(err);
        }
      })
  );

export const doDelete = path =>
  new Promise((resolve, reject) =>
    axios({
      url: path,
      baseURL,
      method: 'DELETE',
      withCredentials: true,
    })
      .then(result => resolve(result))
      .catch(err => {
        if (String(path).toLowerCase() !== '/users/me') {
          if (err.response.status === UNAUTHORIZED) {
            window.location.reload();
          }

          reject(err);
        }
      })
  );
