import React, { useState, useEffect } from 'react';
import FormContainer from '../../components/FormContainer';
import * as configActions from '../../actionCreators/Config';
import * as userActions from '../../actionCreators/User';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Chip } from '@mui/material';
import { Button, Modal, Popover, OverlayTrigger } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';

const SubscriptionPreference = ({
  getParameter,
  parameters,
  MatrixData,
  getMatrixData,
  PersonMatrixDetails,
  getPersonMatrixDetails,
  currentUser,
  updatePersonMatrixDetailsFunc,
  updatePersonMatrixDetails,
  AccountManager,
  getAccountManager,
  SendEmail,
  SendEmailFunc,
  getMatrixDescriptions,
  MatrixDescriptions,
  getSchemaFieldValue,
  schemaFieldValue,
}) => {
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAlreadySubscriberModal, setShowAlreadySubscriberModal] = useState(false);
  const [emailNotificationSentModal, setEmailNotificationSentModal] = useState(false);
  const [xAxisXMLElements, setXAxisXMLElements] = useState([]);
  const [yAxisXMLElements, setYAxisXMLElements] = useState([]);
  const [zAxisXMLElements, setZAxisXMLElements] = useState([]);
  const [xmlToAdd, setXmlToAdd] = useState([]);
  const [xmlToRemove, setXmlToRemove] = useState([]);
  const [JSONObj1, setJSONObj1] = useState(undefined);
  const [AccountManagersString, setAccountManagersString] = useState('');
  const [userEntity, setUserEntity] = useState('');
  const [allParameters, setAllParameters] = useState({});
  const history = useHistory();
  const locate = useLocation();

  let urlParameters = locate.pathname.split('/');
  var redirectname = urlParameters[2];
  useEffect(() => {
    getParameter(
      'sRWClientSubTitle,sRWClientSubHeading,sRWClientSubPayDialogText,sRWClientAlreadySubscribedText,sRWClientSubConfigJSON,scUsrMatrixFieldId,scCntMatrixFieldId,sRWClientSubEmailSenderID,sCommonEmailDomain'
    );
    getMatrixData();

    getAccountManager(currentUser.data.id);
    getMatrixDescriptions(0);
  }, []);

  useEffect(() => {
    if (parameters != null && !parameters.hasOwnProperty('sRWClientSubEmailPublicNoCCIDs')) {
      getParameter(
        'sRWClientSubEmailPublicNoCCIDs,sRWClientSubEmailPublicNoBccIDs,sRWClientSubEmailExstClientCCIDs,sRWClientSubEmailExstClientBccIDs,sRWClientSubEmailPublicYesCCIDs,sRWClientSubEmailPublicYesBccIDs,sRWClientSubRelevantZAxis'
      );
    }
    if (parameters != null) {
      setAllParameters(prevState => ({ ...prevState, ...parameters }));
    }
    if (parameters?.sRWClientSubConfigJSON) {
      setJSONObj1(JSON.parse(parameters?.sRWClientSubConfigJSON));
    }
  }, [parameters]);

  useEffect(() => {
    if (userEntity != '') {
      getPersonMatrixDetails(currentUser.data.id, userEntity);
    }
  }, [userEntity]);

  useEffect(() => {
    if (AccountManager.loading == false && AccountManager.data.length) {
      const parser = new DOMParser();
      const xml = parser.parseFromString(AccountManager.data, 'application/xml');
      const matchingElement = xml.querySelector(`rel-type[name="Account Manager"]`);
      if (matchingElement) {
        const personsArray = matchingElement.getElementsByTagName('rel');

        let AccountManagersArray = Array.from(personsArray).map(item =>
          item.getAttribute('person2-id')
        );
        let AccMngStr = '';
        AccountManagersArray.map((item, index) => {
          if (index == 0) {
            AccMngStr += `${item}`;
          } else {
            AccMngStr += `,${item}`;
          }
        });
        setAccountManagersString(AccMngStr);
      }
      const myEntityData = xml
        .querySelector(`psn[id="${currentUser.data.id}"]`)
        .getAttribute('ent');
      const userEnt = myEntityData.replace(/\s+/g, '');
      setUserEntity(userEnt);
    }
  }, [AccountManager]);

  useEffect(() => {
    if (MatrixData.loading == false && MatrixData.data) {
      // console.log('Matrix Data =====>', MatrixData);
      const parser = new DOMParser();
      const xml = parser.parseFromString(MatrixData.data, 'application/xml');
      // console.log('xml parsed =====>', xml);
      const xAxisItems = xml.getElementsByTagName('X');
      const xAxisElementList = Array.from(xAxisItems).map(item => ({
        id: item.getAttribute('id'),
        name: item.getAttribute('name'),
      }));
      const YAxisItems = xml.getElementsByTagName('Y');
      const YAxisElementList = Array.from(YAxisItems).map(item => ({
        id: item.getAttribute('id'),
        name: item.getAttribute('name'),
      }));
      const zAxisItems = xml.getElementsByTagName('Z');
      const zAxisElementList = Array.from(zAxisItems).map(item => ({
        id: item.getAttribute('id'),
        name: item.getAttribute('name'),
      }));
      setXAxisXMLElements(xAxisElementList);
      setYAxisXMLElements(YAxisElementList);
      setZAxisXMLElements(zAxisElementList);
      // console.log('xAxisElementList =====>', xAxisElementList);
      // console.log('YAxisElementList =====>', YAxisElementList);
      // console.log('zAxisElementList =====>', zAxisElementList);
    }
  }, [MatrixData]);

  useEffect(() => {
    if (
      PersonMatrixDetails.loading == false &&
      PersonMatrixDetails.data &&
      MatrixData.loading == false &&
      MatrixData.data
    ) {
      const parser = new DOMParser();
      const xml = parser.parseFromString(PersonMatrixDetails.data, 'application/xml');
      const matrixDataXML = parser.parseFromString(MatrixData.data, 'application/xml');
      const YAxisItems = xml.getElementsByTagName('y');
      const YAxisSelectedElementList = [];
      Array.from(YAxisItems).map(item => {
        const ParentMatchingElement = matrixDataXML.querySelector(
          `Y[parentId="${item.getAttribute('value')}"]`
        );

        const zValue = item.querySelector('z').textContent;
        if (allParameters?.sRWClientSubRelevantZAxis?.includes(zValue)) {
          if (ParentMatchingElement) {
            const ParentChildMatchingElement = matrixDataXML.querySelectorAll(
              `Y[parentId="${item.getAttribute('value')}"]`
            );
            for (let i = 0; i < ParentChildMatchingElement.length; i++) {
              YAxisSelectedElementList.push(
                Number(ParentChildMatchingElement[i].getAttribute('id'))
              );
            }
          } else {
            YAxisSelectedElementList.push(Number(item.getAttribute('value')));
          }
        }
      });
      setSelectedPreferences(YAxisSelectedElementList);
    }
  }, [PersonMatrixDetails, MatrixData, allParameters]);

  const handleSelectPreference = value => {
    const array = [...selectedPreferences];
    const addXmlArray = [...xmlToAdd];
    const removeXmlArray = [...xmlToRemove];
    const parser = new DOMParser();
    const xml = parser.parseFromString(PersonMatrixDetails.data, 'application/xml');
    const MatrixDataXML = parser.parseFromString(MatrixData.data, 'application/xml');
    // Get element with matching value (for example, an item with id="2")
    const matchingElement = xml.querySelector(`y[value="${value}"]`);
    if (matchingElement) {
      const zValue = matchingElement.querySelector('z').textContent;
      if (zAxisXMLElements.find(item => item.id == zValue).name == 'Subscriber') {
        setShowAlreadySubscriberModal(true);
        return;
      }
    }
    if (!matchingElement && selectedPreferences.includes(value)) {
      const childsParentId = MatrixDataXML.querySelector(`Y[id="${value}"]`).getAttribute(
        'parentId'
      );
      const ParentMatchingElement = xml.querySelector(`y[value="${childsParentId}"]`);
      if (ParentMatchingElement) {
        const zValue = ParentMatchingElement.querySelector('z').textContent;
        if (zAxisXMLElements.find(item => item.id == zValue).name == 'Subscriber') {
          setShowAlreadySubscriberModal(true);
          return;
        }
      }
    }
    const index = array.indexOf(value);

    if (index > -1) {
      // Value is present, so remove it
      if (matchingElement) {
        removeXmlArray.push(value);
      }
      if (!matchingElement && selectedPreferences.includes(value)) {
        const childsParentId = Number(
          MatrixDataXML.querySelector(`Y[id="${value}"]`).getAttribute('parentId')
        );
        if (!removeXmlArray.includes(childsParentId)) {
          removeXmlArray.push(childsParentId);
        }
        removeXmlArray.push(value);
        const ParentChildMatchingElement = MatrixDataXML.querySelectorAll(
          `Y[parentId="${childsParentId}"]`
        );
        let childIdsArray = [];
        for (let i = 0; i < ParentChildMatchingElement.length; i++) {
          childIdsArray.push(Number(ParentChildMatchingElement[i].getAttribute('id')));
        }
        childIdsArray.map(item => {
          if (!removeXmlArray.includes(item)) {
            addXmlArray.push(item);
          }
        });
      }
      const addIndex = addXmlArray.indexOf(value);
      if (addIndex > -1) {
        addXmlArray.splice(addIndex, 1);
      }
      array.splice(index, 1);
    } else {
      // Value is not present, so add it
      if (!matchingElement) {
        addXmlArray.push(value);
      }
      const removeIndex = removeXmlArray.indexOf(value);
      if (removeIndex > -1) {
        removeXmlArray.splice(removeIndex, 1);
      }
      array.push(value);
    }
    setXmlToAdd(addXmlArray);
    setXmlToRemove(removeXmlArray);
    setSelectedPreferences(array);
  };

  function isPublicEmail(email) {
    // List of known public email domains
    const publicEmailDomains = allParameters?.sCommonEmailDomain.split(',');

    // Extract the domain from the email
    const domain = email.split('@')[1];

    // Check if the domain is in the list of public domains
    return publicEmailDomains.includes(domain.toLowerCase());
  }

  const paymentModalClickhandler = paymentAnswer => {
    setShowModal(false);
    let xmlToAddStr = '';
    let xmlToRemoveStr = '';
    const parser = new DOMParser();
    const xml = parser.parseFromString(PersonMatrixDetails.data, 'application/xml');
    const MatrixDataXML = parser.parseFromString(MatrixData.data, 'application/xml');
    xmlToAdd.map((add, index) => {
      let zValue = 2120300010;
      if (selectedPreferences.includes(add)) {
        const childsParentId = Number(
          MatrixDataXML.querySelector(`Y[id="${add}"]`).getAttribute('parentId')
        );
        const matchingElementParent = xml.querySelector(`y[value="${childsParentId}"]`);
        if (matchingElementParent) {
          zValue = matchingElementParent.querySelector('z').textContent;
        }
      }
      if (index == 0 && index == xmlToAdd.length - 1) {
        xmlToAddStr += `<matrix><x>300001<y>${add}<z>${zValue}</z></y></x></matrix>`;
      } else if (index == 0) {
        xmlToAddStr += `<matrix><x>300001<y>${add}<z>${zValue}</z></y>`;
      } else if (index == xmlToAdd.length - 1) {
        xmlToAddStr += `<y>${add}<z>${zValue}</z></y></x></matrix>`;
      } else {
        xmlToAddStr += `<y>${add}<z>${zValue}</z></y>`;
      }
    });

    xmlToRemove.map((remove, index) => {
      const matchingElement = xml.querySelector(`y[value="${remove}"]`);
      let zValue = -1;
      if (matchingElement) {
        zValue = matchingElement.querySelector('z').textContent;
      }
      if (!matchingElement) {
        const childsParentId = Number(
          MatrixDataXML.querySelector(`Y[id="${remove}"]`).getAttribute('parentId')
        );
        const matchingElementParent = xml.querySelector(`y[value="${childsParentId}"]`);
        if (matchingElementParent) {
          zValue = matchingElementParent.querySelector('z').textContent;
        }
      }
      if (index == 0 && index == xmlToRemove.length - 1) {
        xmlToRemoveStr += `<matrix><x>300001<y>${remove}<z>${zValue}</z></y></x></matrix>`;
      } else if (index == 0) {
        xmlToRemoveStr += `<matrix><x>300001<y>${remove}<z>${zValue}</z></y>`;
      } else if (index == xmlToRemove.length - 1) {
        xmlToRemoveStr += `<y>${remove}<z>${zValue}</z></y></x></matrix>`;
      } else {
        xmlToRemoveStr += `<y>${remove}<z>${zValue}</z></y>`;
      }
    });
    let userType;
    let psnSchemaId;
    let psnSchemaFieldId;
    if (userEntity == 'U') {
      userType = 'U';
      psnSchemaId = 'scUsrMatrix';
      psnSchemaFieldId = allParameters?.scUsrMatrixFieldId;
    } else {
      userType = 'C';
      psnSchemaId = 'scCntMatrix';
      psnSchemaFieldId = allParameters?.scCntMatrixFieldId;
    }

    updatePersonMatrixDetailsFunc(
      currentUser.data.id,
      userType,
      xmlToAddStr,
      xmlToRemoveStr,
      psnSchemaId,
      psnSchemaFieldId
    );

    if (paymentAnswer == 'Yes') {
      if (AccountManagersString != '') {
        SendEmailFunc(
          'Your interest in Aletheia Capital',
          'sWebEmlTMPLTExstClient',
          allParameters?.sRWClientSubEmailSenderID,
          currentUser.data.id,
          allParameters?.sRWClientSubEmailExstClientCCIDs != ''
            ? AccountManagersString + ',' + allParameters?.sRWClientSubEmailExstClientCCIDs
            : AccountManagersString,
          allParameters?.sRWClientSubEmailExstClientBccIDs,
          `{'@@TMPLT_PUBTYPE': 'Website','@@TMPLT_TITLE': 'Signup Preferences','@@TMPLT_SUBTITLE': 'Aletheia Capital','@@TO_NAME': '${currentUser.data.full_name}','@@FROM_NAME': 'Vicky Lee','@@FROM_EMAIL': ''}`
        );
      } else {
        if (!isPublicEmail(currentUser.data.email)) {
          SendEmailFunc(
            'Your interest in Aletheia Capital',
            'sWebEmlTMPLTPublicNo',
            allParameters?.sRWClientSubEmailSenderID,
            currentUser.data.id,
            allParameters?.sRWClientSubEmailPublicNoCCIDs,
            allParameters?.sRWClientSubEmailPublicNoBccIDs,
            `{'@@TMPLT_PUBTYPE': 'Website','@@TMPLT_TITLE': 'Signup Preferences','@@TMPLT_SUBTITLE': 'Aletheia Capital','@@TO_NAME': '${currentUser.data.full_name}','@@FROM_NAME': 'Vicky Lee','@@FROM_EMAIL': ''}`
          );
        } else {
          SendEmailFunc(
            'Your interest in Aletheia Capital',
            'sWebEmlTMPLTPublicYes',
            allParameters?.sRWClientSubEmailSenderID,
            currentUser.data.id,
            allParameters?.sRWClientSubEmailPublicYesCCIDs,
            allParameters?.sRWClientSubEmailPublicYesBccIDs,
            `{'@@TMPLT_PUBTYPE': 'Website','@@TMPLT_TITLE': 'Signup Preferences','@@TMPLT_SUBTITLE': 'Aletheia Capital','@@TO_NAME': '${currentUser.data.full_name}','@@FROM_NAME': 'Vicky Lee','@@FROM_EMAIL': ''}`
          );
        }
      }
      setEmailNotificationSentModal(true);
    } else {
      if (redirectname == 'settings') {
        history.push('/settings');
      } else {
        history.push('/');
      }
    }
    // if (redirectname == 'settings') {
    //   history.push('/settings');
    // } else {
    //   history.push('/');
    // }
  };
  return (
    <>
      <Modal show={showAlreadySubscriberModal} onHide={() => setShowAlreadySubscriberModal(false)}>
        <Modal.Body>
          <div style={{ padding: '20px', textAlign: 'center', fontSize: '22px' }}>
            {allParameters?.sRWClientAlreadySubscribedText}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <div>
            <Button bsStyle="primary" onClick={() => setShowAlreadySubscriberModal(false)}>
              OK
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={emailNotificationSentModal}
        onHide={() => {
          setEmailNotificationSentModal(false);
          if (redirectname == 'settings') {
            history.push('/settings');
          } else {
            history.push('/');
          }
        }}
      >
        <Modal.Body>
          <div style={{ padding: '20px', textAlign: 'center', fontSize: '22px' }}>
            You will be contacted shortly by email.
          </div>
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <div>
            <Button
              bsStyle="primary"
              onClick={() => {
                setEmailNotificationSentModal(false);
                if (redirectname == 'settings') {
                  history.push('/settings');
                } else {
                  history.push('/');
                }
              }}
            >
              OK
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <div style={{ padding: '20px', textAlign: 'center', fontSize: '22px' }}>
            {allParameters?.sRWClientSubPayDialogText}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="save-button">
            <Button bsStyle="primary" onClick={() => paymentModalClickhandler('No')}>
              No
            </Button>
            <Button bsStyle="primary" onClick={() => paymentModalClickhandler('Yes')}>
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <FormContainer>
        <div style={{ paddingTop: 30 }}>
          <div style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold' }}>
            {allParameters?.sRWClientSubTitle}
          </div>

          <div style={{ textAlign: 'center', fontSize: '12px' }}>
            {allParameters?.sRWClientSubHeading}
          </div>
          <br />
          <hr />
          <div style={{ marginTop: '20px' }}>
            {JSONObj1 != undefined &&
              JSONObj1.categories.map((item, index) => (
                <div style={{ marginBottom: '20px' }}>
                  <div
                    style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px' }}
                    key={index}
                  >
                    {item.label}
                  </div>
                  {/* <Stack direction="row" spacing={2} alignItems="center"> */}
                  <div>
                    {item.items.map((subCat, index) => {
                      const parser = new DOMParser();
                      let description = null;
                      const xml = parser.parseFromString(
                        PersonMatrixDetails.data,
                        'application/xml'
                      );
                      const MatrixDataXML = parser.parseFromString(
                        MatrixData.data,
                        'application/xml'
                      );
                      // Get element with matching value (for example, an item with id="2")
                      const matchingElement = xml.querySelector(`y[value="${subCat}"]`);
                      let zValue = -1;
                      if (matchingElement) {
                        zValue = matchingElement.querySelector('z').textContent;
                      }

                      if (!matchingElement && selectedPreferences.includes(subCat)) {
                        const childsParentId = MatrixDataXML.querySelector(
                          `Y[id="${subCat}"]`
                        ).getAttribute('parentId');
                        const ParentMatchingElement = xml.querySelector(
                          `y[value="${childsParentId}"]`
                        );
                        if (ParentMatchingElement) {
                          zValue = ParentMatchingElement.querySelector('z').textContent;
                        }
                      }
                      const match = MatrixDescriptions?.data.find(item => item.iPBV_Id == subCat);
                      if (match) {
                        // If a match is found, set the input field value to the matching description
                        description = match.sDescription;
                      }
                      if (zValue != -1 && JSONObj1.colors.find(color => color.item == zValue)) {
                        const color = JSONObj1.colors.find(color => color.item == zValue).color;

                        return (
                          <OverlayTrigger
                            trigger={description != null ? ['hover', 'focus'] : null}
                            placement="top"
                            overlay={
                              description != null ? (
                                <Popover id="popover-trigger-hover-focus">{description}</Popover>
                              ) : (
                                <Popover id="popover-trigger-hover-focus">No description</Popover>
                              )
                            }
                          >
                            <Chip
                              label={
                                yAxisXMLElements.length
                                  ? yAxisXMLElements.find(item => item.id == subCat).name
                                  : null
                              }
                              style={{
                                fontSize: '14px',
                                color: 'black',
                                fontFamily: 'open_sansregular',
                                marginRight: '10px',
                                marginBottom: '10px',
                                cursor: 'pointer',
                                backgroundColor: { color },
                              }}
                              onClick={() => handleSelectPreference(subCat)}
                            />
                          </OverlayTrigger>
                        );
                      }
                      return (
                        <>
                          {selectedPreferences.includes(subCat) ? (
                            <OverlayTrigger
                              trigger={description != null ? ['hover', 'focus'] : null}
                              placement="top"
                              overlay={
                                description != null ? (
                                  <Popover id="popover-trigger-hover-focus">{description}</Popover>
                                ) : (
                                  <Popover id="popover-trigger-hover-focus">No description</Popover>
                                )
                              }
                            >
                              <Chip
                                label={
                                  yAxisXMLElements.length
                                    ? yAxisXMLElements.find(item => item.id == subCat).name
                                    : null
                                }
                                style={{
                                  fontSize: '14px',
                                  color: 'white',
                                  fontFamily: 'open_sansregular',
                                  marginRight: '10px',
                                  marginBottom: '10px',
                                  cursor: 'pointer',
                                  backgroundColor: '#337ab7',
                                }}
                                onClick={() => handleSelectPreference(subCat)}
                              />
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              trigger={description != null ? ['hover', 'focus'] : null}
                              placement="top"
                              overlay={
                                description != null ? (
                                  <Popover id="popover-trigger-hover-focus">{description}</Popover>
                                ) : (
                                  <Popover id="popover-trigger-hover-focus">No description</Popover>
                                )
                              }
                            >
                              <Chip
                                label={
                                  yAxisXMLElements.length
                                    ? yAxisXMLElements.find(item => item.id == subCat).name
                                    : null
                                }
                                variant="outlined"
                                style={{
                                  fontSize: '14px',
                                  color: '#7e8387',
                                  fontFamily: 'open_sansregular',
                                  marginRight: '10px',
                                  marginBottom: '10px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleSelectPreference(subCat)}
                              />
                            </OverlayTrigger>
                          )}
                        </>
                      );
                    })}
                    {/* </Stack> */}
                  </div>
                </div>
              ))}
          </div>
          <br />
          <hr />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Link
              href={redirectname == 'settings' ? '/settings' : '/'}
              to={redirectname == 'settings' ? '/settings' : '/'}
            >
              <Button>Back</Button>
            </Link>
            <Button
              bsStyle="primary"
              onClick={
                xmlToRemove.length && xmlToAdd.length == 0
                  ? () => paymentModalClickhandler('No')
                  : () => setShowModal(true)
              }
              disabled={xmlToAdd.length || xmlToRemove.length ? false : true}
            >
              Save
            </Button>
          </div>
        </div>
      </FormContainer>
    </>
  );
};

const mapStateToProps = ({
  user,
  user: { currentUser, PersonMatrixDetails, UpdatePersonMatrixDetails, AccountManager, SendEmail },
  Config: { parameters, MatrixData, MatrixDescriptions, schemaFieldValue },
}) => ({
  parameters,
  MatrixData,
  PersonMatrixDetails,
  currentUser,
  UpdatePersonMatrixDetails,
  AccountManager,
  SendEmail,
  MatrixDescriptions,
  schemaFieldValue,
});

const mapDispatchToProps = dispatch => ({
  getParameter: bindActionCreators(configActions.getParameter, dispatch),
  getMatrixData: bindActionCreators(configActions.getMatrixData, dispatch),
  getSchemaFieldValue: bindActionCreators(configActions.getSchemaFieldValue, dispatch),
  getMatrixDescriptions: bindActionCreators(configActions.getMatrixDescriptions, dispatch),
  getPersonMatrixDetails: bindActionCreators(userActions.getPersonMatrixDetails, dispatch),
  updatePersonMatrixDetailsFunc: bindActionCreators(
    userActions.updatePersonMatrixDetails,
    dispatch
  ),
  getAccountManager: bindActionCreators(userActions.getAccountManager, dispatch),
  SendEmailFunc: bindActionCreators(userActions.SendEmail, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPreference);
