/*
**************************************************************************************************************
												R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author	:	Nick Ng
	Date	:	10 Oct 2022
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************
  M002 : 27 March 2023 : Zeeshan
 * M-0003827: Forgot Password should send an email with link to reset password
	M001 : 17 Oct 2022 : Nick Ng : Core
		 * M-4173: Resend of email verification email if the user tries to login or clicks on Forgot Password
**************************************************************************************************************
*/

import Careers from '../pages/Careers';
import Contact from '../pages/Contact';
import Error from '../pages/Error';
import Firm from '../pages/Firm';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Main from '../pages/Main';
import Message from '../pages/Message';
import People from '../pages/People';
import ReportsManager from '../pages/ReportsManager';
import ReportViewer from '../pages/ReportViewer';
import Search from '../pages/Search';
import Signup from '../pages/Signup';
import RegulatoryDisclosure from '../pages/RegulatoryDisclosure';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfUse from '../pages/TermsOfUse';
import Analyst from '../pages/Analyst';
import Sector from '../pages/Sector';
import Company from '../pages/Company';
import StyleGuide from '../pages/StyleGuide';
import UsersManagerManager from '../pages/UsersManager';
import Settings from '../pages/Settings';
import SiteManagement from '../pages/SiteManagement';
import TeamManagement from '../pages/TeamManagement';
import OurFirmManagement from '../pages/OurFirmManagement';
import Post from '../pages/Post';
import FavouritesManagment from '../pages/FavouritesManagment';
import BlogEditor from '../pages/BlogEditor';
import AletheiaConnect from '../pages/AletheiaConnect';
// import AletheiaConnectManagement from '../pages/AletheiaConnectManagement';
import SavedResearches from '../pages/SavedResearches';
import BlogsLanding from '../pages/BlogsLanding';
import AletheiaConnectReg from '../pages/AletheiaConnectRegistration';
import AletheiaConnectApproval from '../pages/AletheiaConnectApproval';
import Companies from '../pages/Companies';
import AletheiaAOI from '../pages/AletheiaAOI';
import Verify from '../pages/Verify'; //M001
import ResendVerify from '../pages/ResendVerify'; //M001
import VerifyCookieEmail from '../pages/VerifyCookieEmail'; //M001
import ResetPassword from '../pages/ResetPassword'; //M002
import ConnectAppointment from '../pages/ConnectAppointment';
import AletheiaAccess from '../pages/AletheiaAccess';
import CustomPages from '../pages/CustomPages';
import SubscriptionPreference from '../pages/SubscriptionPreference';
import UnsubSubscription from '../pages/UnsubscribeSubscription';

export default [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/login/:signupPreference',
    component: Login,
  },
  {
    path: '/signup',
    component: Signup,
  },
  //==========================M001==========[===============
  {
    path: '/verify',
    component: Verify,
  },
  {
    path: '/resendVerify',
    component: ResendVerify,
  },
  {
    path: '/verifyCookieEmail',
    component: VerifyCookieEmail,
  },
  {
    path: '/subscriptionPreference/:back',
    component: SubscriptionPreference,
  },
  //==========================M001==========]===============
  {
    path: '/',
    default: true,
    meta: {
      layout: 'secondary',
    },

    component: Main,
  },
  {
    path: '/',
    default: true,
    meta: {
      layout: 'secondary',
      auth: true,
    },
    component: Main,
  },
  {
    path: '/access',
    meta: {
      layout: 'main',
    },

    component: AletheiaAccess,
  },
  {
    path: '/home',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: Home,
  },
  {
    path: '/SavedResearches',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: SavedResearches,
  },
  {
    path: '/search/:q',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: Search,
  },
  {
    error: true,
    component: Error,
  },
  {
    path: '/styleGuide',
    meta: {
      layout: 'main',
    },

    component: StyleGuide,
  },
  {
    path: '/about',
    meta: {
      layout: 'main',
    },

    component: Firm,
  },
  {
    path: '/people',
    meta: {
      layout: 'main',
    },

    component: People,
  },
  {
    path: '/careers',
    meta: {
      layout: 'main',
    },

    component: Careers,
  },
  {
    path: '/connect/videos/:id',
    meta: {
      layout: 'main',
    },
    component: AletheiaConnect,
  },
  {
    path: '/page/:id',
    meta: {
      layout: 'main',
    },
    component: CustomPages,
  },
  {
    path: '/connect/videos',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: AletheiaConnect,
  },
  {
    path: '/unsubscribe/:id',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: UnsubSubscription,
  },
  {
    path: '/connect/registration',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: AletheiaConnectReg,
  },
  {
    path: '/connect/appointment',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: ConnectAppointment,
  },
  {
    path: '/connect/appointment/:id',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: ConnectAppointment,
  },
  {
    path: '/contact',
    meta: {
      layout: 'main',
    },

    component: Contact,
  },
  {
    path: '/reports/:id',
    meta: {
      layout: 'main',
      // auth: true,
    },
    component: ReportViewer,
  },
  {
    path: '/rfs.htm',
    meta: {
      layout: 'main',
    },

    component: ReportViewer,
  },
  {
    path: '/admin/reports',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: ReportsManager,
  },
  {
    path: '/admin/users',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: UsersManagerManager,
  },
  {
    path: '/analysts/:id',
    meta: {
      layout: 'main',
    },

    component: Analyst,
  },
  {
    path: '/post/:id',
    meta: {
      layout: 'main',
    },
    component: Post,
  },
  {
    path: '/admin/createPost',
    meta: {
      layout: 'main',
    },
    component: BlogEditor,
  },
  {
    path: '/admin/favorites',
    meta: {
      layout: 'main',
    },

    component: FavouritesManagment,
  },
  {
    path: '/settings',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: Settings,
  },
  {
    path: '/admin/site-management',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: SiteManagement,
  },
  {
    path: '/admin/OurFirmManagement',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: OurFirmManagement,
  },
  {
    path: '/admin/team-management',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: TeamManagement,
  },
  // {
  //   path: '/admin/aletheia_connect',
  //   meta: {
  //     layout: 'main',
  //     // auth: true,
  //   },
  //   component: AletheiaConnectManagement,
  // },
  {
    path: '/sectors/:product',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: Sector,
  },
  {
    path: '/companies/:id',
    meta: {
      layout: 'main',
      auth: true,
    },

    component: Company,
  },
  {
    path: '/message/:type',
    meta: {
      layout: 'main',
    },

    component: Message,
  },
  {
    path: '/regulatory',
    meta: {
      layout: 'main',
    },

    component: RegulatoryDisclosure,
  },
  {
    path: '/privacy',
    meta: {
      layout: 'main',
    },

    component: PrivacyPolicy,
  },
  {
    path: '/terms',
    meta: {
      layout: 'main',
    },

    component: TermsOfUse,
  },
  {
    path: '/blogs',
    meta: {
      layout: 'main',
    },
    component: BlogsLanding,
  },
  {
    path: '/blogs/:id',
    meta: {
      layout: 'main',
    },
    component: BlogsLanding,
  },
  {
    path: '/admin/connect/approval',
    meta: {
      layout: 'main',
      auth: true,
    },
    component: AletheiaConnectApproval,
  },
  {
    path: '/companies',
    meta: {
      layout: 'main',
      auth: true,
    },
    component: Companies,
  },
  {
    path: '/AOI/:type',
    meta: {
      layout: 'main',
      auth: false,
    },
    component: AletheiaAOI,
  },
  {
    path: '/connect/registration/:id',
    meta: {
      layout: 'main',
      auth: true,
    },
    component: AletheiaConnectReg,
  },
  //==========================M002==========[===============
  {
    path: '/resetpassword',
    component: ResetPassword,
  },
  //==========================M002==========[===============
];
