import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as configActions from '../../actionCreators/Config';
import parse from 'html-react-parser';

const CustomPage = ({ getParameter, content, getCustomPageParameter, customContent }) => {
  const location = useLocation();

  let urlParameters = location.pathname.split('/');
  var id = urlParameters[2];

  // Get the query parameters
  const searchParams = new URLSearchParams(location.search);

  // Extract a specific query parameter (e.g., pageid)
  const pageId = searchParams.get('pageid');

  const [pageHTML, setPageHTML] = useState('');

  React.useEffect(() => {
    getParameter(id);
    // getCustomPageParameter(id);
  }, [id]);

  const smoothScrollTo = (targetElement, marginFromTop = 80) => {
    const startPosition = window.scrollY; // Current scroll position
    const targetPosition =
      targetElement.getBoundingClientRect().top + startPosition - marginFromTop; // Adjusted final position
    const distance = targetPosition - startPosition; // Distance to scroll
    const duration = 800; // Duration of the animation in milliseconds
    let startTime = null;

    // Easing function for smoother animation
    const easeInOutQuad = t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

    const animationStep = currentTime => {
      if (!startTime) startTime = currentTime;

      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Ensure progress doesn't exceed 1

      const scrollAmount = easeInOutQuad(progress) * distance + startPosition;

      window.scrollTo(0, scrollAmount);

      if (elapsedTime < duration) {
        requestAnimationFrame(animationStep);
      }
    };

    requestAnimationFrame(animationStep);
  };

  useEffect(() => {
    if (pageId != null && pageHTML !== '') {
      const element = document.getElementById(pageId);
      if (element) {
        smoothScrollTo(element);
      }
    }
  }, [pageId, pageHTML]);

  React.useEffect(() => {
    if (content != null && content[id]) {
      const parsedHTML = parse(content[id]);
      setPageHTML(parsedHTML);
    } else {
      console.log('else entered');
    }
  }, [content]);
  return (
    <>
      {pageHTML != '' ? pageHTML : null}
      {/* <div>index</div> */}
    </>
  );
};

function mapStateToProps(state) {
  const content = state.Config.parameters;
  const customContent = state.Config.customPageParameter;
  return { content, customContent };
}

const mapDispatchToProps = dispatch => ({
  getParameter: bindActionCreators(configActions.getParameter, dispatch),
  getCustomPageParameter: bindActionCreators(configActions.getCustomPageParameter, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomPage));
